<template>
  <v-container class="general">
    <template v-if="!isLoading">
      <page-title :component="'GroupTicketTemplates'">
        <template v-slot:actions>
          <LanguageSwitcher />
        </template>
      </page-title>
      <transition name="fade" mode="out-in">
        <v-layout
          v-if="!isLoading && !groupPlugins.length"
          class="row wrap text-center"
        >
          <v-flex class="xs12 sw-h5 font-weight-light">
            {{ $t("ticket_template_list_page.nothing_created") }}
          </v-flex>
        </v-layout>
      </transition>
      <v-layout v-if="groupPlugins && groupPlugins.length > 0" class="row wrap">
        <v-flex class="xs12">
          <v-card class="px-4 py-3 border-radius">
            <v-layout
              v-for="(groupPlugin, i) in groupPlugins"
              :key="`group-plugin-${i}`"
              :style="{ width: '100%' }"
              class="row wrap align-center"
            >
              <v-flex v-if="i !== 0" class="xs12 my-3">
                <v-divider></v-divider>
              </v-flex>
              <v-flex class="xs12">
                <v-layout class="row nowrap align-center">
                  <v-flex class="xs12">
                    <a
                      class="theme-primary"
                      @click.stop="openWizard(groupPlugin.id)"
                      >{{ groupPlugin.name }}</a
                    >
                  </v-flex>
                  <div>
                    <v-btn icon @click.stop="deleteGroupPlugin(groupPlugin.id)">
                      <font-awesome-icon class="theme-danger" icon="trash" />
                    </v-btn>
                  </div>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-card>
        </v-flex>
      </v-layout>
      <AppSpeedDial
        @click="$router.push({ name: 'ticket_templates_create' })"
        :hint="'Create tickets'"
      />
    </template>
    <ConfirmationDialog ref="confirmationDialog" />
  </v-container>
</template>

<script>
import LanguageSwitcher from "@/components/LanguageSwitcher.vue";

export default {
  data: () => ({
    isLoading: false,
    groupPlugins: [],
    perPage: 100,
  }),
  computed: {
    groupId() {
      return this.$route.params.group_id;
    },
  },
  components: {
    LanguageSwitcher,
  },
  mounted() {
    this.listGroupPlugins("ticketwizard");
  },
  methods: {
    openWizard(groupPluginId) {
      if (!groupPluginId) return;

      this.$router
        .push({
          name: "ticket_templates_edit",
          params: {
            group_plugin_id: groupPluginId,
          },
        })
        .catch(() => {});
    },
    listGroupPlugins(prefix) {
      this.isLoading = true;

      const params = [
        this.groupId,
        {
          prefix: prefix,
          with_attributes: 1,
          per_page: this.perPage,
        },
      ];

      this.$api.groupPlugins
        .list(...params)
        .then((response) => {
          if (response && response.data.data.length === 1) {
            this.openWizard(response.data.data[0].id);
          } else {
            this.isLoading = false;
            this.groupPlugins = response.data.data;
          }
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    async deleteGroupPlugin(id) {
      if (!id) return;

      const confirmed = await this.$refs.confirmationDialog.open(
        "Are you sure you want delete this ticket template? This can't be undone",
        this.$t("no"),
        this.$t("yes"),
      );

      if (!confirmed) return;

      this.$api.groupPlugins
        .delete(this.groupId, id)
        .then((response) => {
          if (response.status === 200) {
            this.listGroupPlugins("ticketwizard");
          }
        })
        .catch((error) => this.errorMessageShow(error));
    },
  },
  watch: {
    "$route.params": {
      handler() {
        this.listGroupPlugins("ticketwizard");
      },
    },
  },
};
</script>

<style scoped></style>
